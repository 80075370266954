import Head from 'next/head';
import type { GetServerSideProps, InferGetStaticPropsType } from 'next';
import React, { useMemo } from 'react';
import { AppSettings, PageType, pageMetadataMap } from '@/shared/app-common';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import { fetchLoadingDataWith, getArticleRecommendList } from '@/lib/service';
import { NextPageWithLayout } from '@/pages/_app';
import Layout from '@/layouts';
import { canonicalUrl } from '@/shared/utils';
import { getTdkApi } from '@/lib/service/tdk';
import { page2JsonLd } from '@/lib/jsonld';
import { hyphenatedToTitleCase } from '@/shared/path-helper';

const HomeContent = dynamic(() => import('@/components/Home'), {
  ssr: true,
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale, req, resolvedUrl, params } = context;
  const { id } = params;

  const [localeRes, articleList, tdkRes, dataRes] = await Promise.all([
    serverSideTranslations(locale, ['common']),
    getArticleRecommendList(null, locale),
    getTdkApi(resolvedUrl.split('?')[0], locale),
    fetchLoadingDataWith('/', locale),
  ]);

  const tempData = localeRes['_nextI18Next']['initialI18nStore'][locale]['common'] ?? {};
  // 设置默认值
  let pageType = PageType.Normal;

  // 根据 id 确定页面类型
  if (id && Object.values(PageType).includes(id as PageType)) {
    pageType = id as PageType;
  }
  // 获取相应的元数据
  const metadata = pageMetadataMap[pageType];
  const siteUrl =
    locale !== 'en' ? `https://${req.headers.host}/${locale}` : `https://${req.headers.host}`;
  // 设置标题和描述
  const title =
    tdkRes?.data?.title ||
    tempData[metadata.titleKey] ||
    tempData.head_Meta_Title ||
    'Default Title';
  const description =
    tdkRes?.data?.description ||
    tempData[metadata.descriptionKey] ||
    tempData.head_Meta_Desc ||
    'Default Description';

  const jsonLd = page2JsonLd(
    {
      title: hyphenatedToTitleCase(`${id}`),
      desc: description,
      url: canonicalUrl(req.headers.host, resolvedUrl, locale),
    },
    {
      url: siteUrl,
    },
  );
  return {
    props: {
      ...localeRes,
      herf: canonicalUrl(req.headers.host, resolvedUrl, locale),
      locale,
      articleList: articleList?.data ?? [],
      title: title,
      description: description,
      pageType: pageType,
      contentData: dataRes?.data ?? null,
      jsonLd,
    },
  };
};

const Home: NextPageWithLayout<InferGetStaticPropsType<typeof getServerSideProps>> = (props) => {
  return useMemo(() => <HomeContent data={props} />, [props]);
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title;
  const desc = page?.props?.description;
  const pageType = page?.props?.pageType;

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Layout
      head={
        <Head>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={desc} />

          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:image" content={AppSettings.logoUrl} />
          <meta property="og:image:secure_url" content={AppSettings.logoUrl} />
          <meta property="og:type" content="summary" />
          <meta property="og:url" content={page.props?.herf} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta name="twitter:image" content={AppSettings.logoUrl} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={page.props?.herf} />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="canonical" href={page.props?.herf} />
          <link rel="icon" href={AppSettings.icoPath} />

          <script
            async
            dangerouslySetInnerHTML={{
              __html: `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "l9myh0u19b");
              `,
            }}
          />
          {page?.props?.jsonLd && (
            <script
              async
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(page?.props?.jsonLd) }}
            />
          )}
        </Head>
      }
      pageType={pageType}
    >
      {page}
    </Layout>
  );
};

export default Home;
